<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="450"
    >
      <v-card dark loading>
          <v-btn
            icon
            @click="dialog = false"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h5">
         Terms &amp; Privacy Policy
        </v-card-title>
        <v-card-text>
            <h4 class="font-weight-bold">How we use your Information</h4>
                <ul>
                    <li>We store them on our servers so you can access them whenever you need them</li>
                    <li>We will <strong>NEVER</strong> sell your information to a 3rd Party</li>
                </ul>
                <br>
                <h4 class="font-weight-bold">Permissions you grant us</h4>
                By using this product, you grant us the permission to
                <ul>
                    <li>Display your non-sensitive School Information on any part of our Website</li>
                    <li>Verify that your school is real and registered</li>
                    <li>SMS/Email you updates related to more Products</li>
                    <li>Terminate your account when you abuse our website either by spamming it or misusing it. (All abnormal activities are logged)</li>
                    <li>You exonerate us from any blames that may arise as a result of your MISUSE of our products/services</li>
                </ul>

                <p><em>We recommend you visit this Page from time to time to keep track with changes</em></p>
                <v-divider></v-divider>
                <p><strong>Last Updated: </strong> <em>30<sup>th</sup> January 2021</em> </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
        name: 'TOS',
        data () {
        return {
            dialog:true
        }
    },
  }
</script>