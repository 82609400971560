<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        cols="12" sm="12" md="6" lg="9"
      >
            <v-card>
                <v-card-title>
                    REGISTER
                </v-card-title>
                <v-card-subtitle class="red--text">Please Ensure Your School is a Real School. Fake Schools will be deleted immediately. Also ensure that you use a valid email address when signing up. Users will non-existing email address will be unable to use the software after signup.</v-card-subtitle>
                <v-card-text>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row>
                            <v-col md="6" cols="12">
                                <v-text-field
                                v-model="record.fullname"
                                :counter="30"
                                :rules="nameRules"
                                label="Name"
                                required
                                ></v-text-field>
                            </v-col>

                            <v-col md="6" cols="12">
                                <v-text-field
                                v-model="record.email"
                                :counter="50"
                                :rules="emailRules"
                                label="E-mail Address"
                                required
                                ></v-text-field>
                            </v-col>

                            <v-col md="6" cols="12">
                                <v-text-field
                                v-model="record.password"
                                :counter="15"
                                :rules="passwordRules"
                                label="Password"
                                type="password"
                                required
                                ></v-text-field>
                            </v-col>

                            <v-col md="6" cols="12">
                                <v-text-field
                                v-model="record.phone"
                                :counter="17"
                                :rules="phoneRules"
                                label="Phone/Mobile Number"
                                required
                                ></v-text-field>
                            </v-col>

                            <v-col md="6" cols="12">
                                <v-text-field
                                v-model="record.school_name"
                                :counter="30"
                                :rules="[v => !!v || 'School name is required!']"
                                label="School Name"
                                required
                                ></v-text-field>
                            </v-col>

                            <v-col md="6" cols="12">
                                <v-text-field
                                v-model="record.motto"
                                :counter="90"
                                :rules="[v => !!v || 'School Motto/Slogan is required!']"
                                label="School Motto/Slogan"
                                required
                                ></v-text-field>
                            </v-col>

                            <v-col md="6" cols="12">
                                <v-text-field
                                v-model="record.address"
                                :counter="100"
                                :rules="[v => !!v || 'Address is required!']"
                                label="School Address"
                                required
                                ></v-text-field>
                            </v-col>

                            <v-col md="6" cols="12">
                                <v-checkbox
                                v-model="record.policy"
                                :rules="[v => !!v || 'You must agree to continue!']"
                                required
                                >
                                <template v-slot:label>
                                    <div>
                                    I agree to the
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <a
                                            @click="tosModal = !tosModal"
                                            v-on="on"
                                        >
                                            Terms &amp; Privacy Policy
                                        </a>
                                        </template>
                                        Click to Read
                                    </v-tooltip>
                                    </div>
                                </template>
                                </v-checkbox>
                                <tos v-if="tosModal"/>
                            </v-col>

                            <v-col md="12">
                                <v-btn
                                :disabled="!valid"
                                color="success"
                                class="mr-4"
                                @click="signup"
                                >
                                Signup
                                </v-btn>

                                <v-btn
                                color="error"
                                class="mr-4"
                                @click="reset"
                                >
                                Reset Form
                                </v-btn>
                            </v-col>
                    </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
      </v-col>
      <v-col
        class="mb-5"
        cols="12" sm="12" md="6" lg="3"
      >
        <auth-menu-card v-if="authToken"/>
        <guest-menu-card v-else/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GuestMenuCard from '../../components/GuestMenuCard.vue'
import HeaderVue from '../../components/Header.vue'
import axios from 'axios';
import AuthMenuCard from '../../components/AuthMenuCard.vue';
import Tos from '../../components/Tos.vue';

  export default {
    name: 'Signup',
    components: {
        GuestMenuCard,
        HeaderVue,
        AuthMenuCard,
        Tos,
    },
    mounted() {
       if (this.authToken) {
           this.$router.push('dashboard')
       }
    },
    data: () => ({
        authToken:sessionStorage.getItem('authToken') ?? null,
        tosModal:false,
        record:{
            fullname:null,
            email:null,
            password:null,
            phone:null,
            school_name:null,
            motto:null,
            address:null,
            policy:null,
        },
        valid: true,
        nameRules: [
            v => !!v || 'Fullname is required',
            v => (v && v.length <= 50) || 'Fullname must be less than 50 characters',
        ],
        passwordRules: [
            v => !!v || 'Password is required',
            v => (v && v.length >= 8) || 'Password must not be less than 8 characters',
        ],
        phoneRules: [
            v => !!v || 'Phone number is required',
            v => (v && v.length >= 10 && v.length <= 17) || 'Phone must be between 10-17 characters',
        ],
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        checkbox: false,
    }),

    methods: {
        signup () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_AUTHURL+'/signup', this.record,
            {
                headers: 
                    {
                        'Device-Referrer-Id': process.env.VUE_APP_DEVICEID
                    }
            })
            .then(() => {
                this.$toast.success('New Account Created! You may now Login', {
                    timeout: 5000
                });
                this.$router.push('login')
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        reset () {
            this.$refs.form.reset()
        },
    },
  }
</script>
